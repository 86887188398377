$(document).ready(function() {

    $("header .locale").each(function() {
        var $element = $(this);
        var $button = $element.children().filter("a");
        var $menu = $element.children().filter(".menu");
        var animation_duration = 200;

        // Open the locale menu
        $button.click(function(e) {
            $menu.slideToggle(animation_duration);
        });

        // Click outside the locale menu when it's open
        $(document).click(function(e) {
            var $target = $(e.target);
            if (!$target.is($element) && $target.closest($element).length === 0) {
                $menu.slideUp(animation_duration);
            }
        });
    });

});